import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import scrollTo from 'gatsby-plugin-smoothscroll';

import {
	MdMenu
  } from 'react-icons/md';




const Header = ({changeHeader})  => {
	const data = useStaticQuery(graphql`
        query {
            logo: file(relativePath: { eq: "logo_xFondo.png" }) {
                childImageSharp {
                    fluid(maxWidth: 350) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
	`)

	return(
		<React.Fragment>
		<nav className="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light site-navbar-target scrolled awake" id="ftco-navbar">
	    <div className="container">
	      <a className="navbar-brand" href="/">
			  <span>
				  <img src={`${data.logo.childImageSharp.fluid.src}`} alt="logo Metalinks" style={{width: '185px', height: '40px'}} />
			  </span>
		  </a>
	      <button className="navbar-toggler js-fh5co-nav-toggle fh5co-nav-toggle" type="button" data-toggle="collapse" data-target="#ftco-nav" aria-controls="ftco-nav" aria-expanded="false" aria-label="Toggle navigation">
			<MdMenu color="7d7d7d" size ="1.5em" />
	      </button>


	      <div className="collapse navbar-collapse" id="ftco-nav">
	        <ul className="navbar-nav nav ml-auto">
			<li className="nav-item">
				{(changeHeader === 1)
					?
						<a	href={'javascript:void(0);'}
							onClick={() => scrollTo('#home-section')}
							className="nav-link">
							<span>Inicio</span>
						</a>
					:
						<Link	to='/'
							className="nav-link"><span>Inicio</span>
						</Link>
				}

			</li>
			<li className="nav-item">
				{(changeHeader === 1)
					?
						<a	href={'javascript:void(0);'}
							onClick={() => scrollTo('#services-section')}
							className="nav-link">
							<span>Servicios</span>
						</a>
					:
						<Link	to='/#services-section'
						className="nav-link"><span>Servicios</span>
						</Link>
				}

			</li>
			<li className="nav-item">
				{(changeHeader === 1)
					?
						<a	href={'javascript:void(0);'}
							onClick={() => scrollTo('#about-section')}
							className="nav-link">
							<span>Nosotros</span>
						</a>
					:
						<Link to='/#about-section'
							className="nav-link"><span>Nosotros</span>
						</Link>
				}
			</li>
			<li className="nav-item">
				{(changeHeader === 1)
					?
						<a	href={'javascript:void(0);'}
							onClick={() => scrollTo('#projects-section')}
							className="nav-link">
							<span>Proyectos</span>
						</a>
					:
						<Link to='/#projects-section'
							className="nav-link"><span>Proyectos</span>
						</Link>
				}
			</li>
			<li className="nav-item">
				{(changeHeader === 1)
					?
						<a	href={'javascript:void(0);'}
							onClick={() => scrollTo('#testimony-section')}
							className="nav-link">
							<span>Testimonios</span>
						</a>
					:
						<Link to='/#testimony-section'
							className="nav-link"><span>Testimonios</span>
						</Link>
				}
			</li>
			<li className="nav-item">
				{(changeHeader === 1)
					?
						<a	href={'javascript:void(0);'}
							onClick={() => scrollTo('#blog-section')}
							className="nav-link">
							<span>Productos</span>
						</a>
					:
						<Link to='/#blog-section'
							className="nav-link"><span>Productos</span>
						</Link>
				}
			</li>
			<li className="nav-item">
				{(changeHeader === 1)
					?
						<a	href={'javascript:void(0);'}
							onClick={() => scrollTo('#contact-section')}
							className="nav-link">
							<span>Contacto</span>
						</a>
					:
						<Link to='/#contact-section'
							className="nav-link"><span>Contacto</span>
						</Link>
				}
			</li>

	        </ul>
	      </div>
	    </div>
	  </nav>
	</React.Fragment>

	)
}

export default Header

