import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import {FaMapMarker,
  FaInstagram,
  FaPhone,
  FaLinkedin
} from 'react-icons/fa';
import {FiMail,FiFacebook,} from 'react-icons/fi';
import scrollTo from 'gatsby-plugin-smoothscroll';



const Footer = ({changeHeader}) => {

  const data = useStaticQuery(graphql`
  query {
    logo: file(relativePath: { eq: "logo_blanco.png" }) {
        childImageSharp {
            fluid(maxWidth: 350) {
                ...GatsbyImageSharpFluid
            }
        }
    }
    
}
`)




  return (
    <>

    <footer className="ftco-footer ftco-section">
      <div className="container">
        <div className="row mb-5">
          <div className="col-md">
            <div className="ftco-footer-widget mb-4">
              <h2 className="ftco-heading-2">Metalinks C.A</h2>
              <p>Somos una empresa especializada en brindar soluciones tecnológicas, basándonos en el descubrimiento del producto.</p>
              <ul className="ftco-footer-social list-unstyled float-md-left float-lft mt-5">
                <li className="" ><a href="https://www.facebook.com/metalinks.ca/" target="_blank"><FiFacebook className="icon_redes" color="#fff" size="2em" /></a></li>
                <li className="" ><a href="https://www.instagram.com/metalinks.ca/"  target="_blank"><FaInstagram className="icon_redes" color="#fff" size="2em" /></a></li>
                <li className="" ><a href="https://www.linkedin.com/in/metalinks-ca"  target="_blank"><FaLinkedin className="icon_redes" color="#fff" size="2em" /></a></li>
              </ul>
            </div>
          </div>
          <div className="col-md">
            <div className="ftco-footer-widget mb-4 ml-md-4">
              <h2 className="ftco-heading-2">Links</h2>
              <ul className="list-unstyled">
                <li className="link_footer">
                    {(changeHeader === 1)
                    ?
                      <a	href={'javascript:void(0);'}  
                        onClick={() => scrollTo('#home-section')} 
                        className="nav-link">
                        <span>Inicio</span>
                      </a>
                    :
                      <Link	to='/#home-section'  
                        className="nav-link"><span>Inicio</span>
                      </Link>
                    }
                </li>
                <li className="link_footer">
                    {(changeHeader === 1)
                    ?
                      <a	href={'javascript:void(0);'}  
                        onClick={() => scrollTo('#about-section')} 
                        className="nav-link">
                        <span>Nosotros</span>
                      </a>
                    :
                      <Link	to='/#about-section'  
                        className="nav-link"><span>Nosotros</span>
                      </Link>
                    }
                </li>
                <li className="link_footer">
                    {(changeHeader === 1)
                    ?
                      <a	href={'javascript:void(0);'}  
                        onClick={() => scrollTo('#services-section')} 
                        className="nav-link">
                        <span>Servicios</span>
                      </a>
                    :
                      <Link	to='/#services-section'  
                        className="nav-link"><span>Servicios</span>
                      </Link>
                    }
                </li>
                <li className="link_footer">
                    {(changeHeader === 1)
                    ?
                      <a	href={'javascript:void(0);'}  
                        onClick={() => scrollTo('#projects-section')} 
                        className="nav-link">
                        <span>Proyectos</span>
                      </a>
                    :
                      <Link	to='/#projects-section'  
                        className="nav-link"><span>Proyectos</span>
                      </Link>
                    }
                </li>
                <li className="link_footer">
                    {(changeHeader === 1)
                    ?
                      <a	href={'javascript:void(0);'}  
                        onClick={() => scrollTo('#contact-section')} 
                        className="nav-link">
                        <span>Contacto</span>
                      </a>
                    :
                      <Link	to='/#contact-section'  
                        className="nav-link"><span>Contacto</span>
                      </Link>
                    }
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md">
             <div className="ftco-footer-widget mb-4">
              <h2 className="ftco-heading-2">Servicios</h2>
              <ul className="list-unstyled">
                <li className="link_footer"><Link to="/software-developer"><span className="icon-long-arrow-right mr-2"></span>Desarrollado web</Link></li>
                <li className="link_footer"><Link to="/marketing-digital"><span className="icon-long-arrow-right mr-2"></span>Marketing Digital</Link></li>
                <li className="link_footer"><Link to="/support-tec"><span className="icon-long-arrow-right mr-2"></span>Soporte Técnico</Link></li>
                <li className="link_footer"><Link to="/consultancy"><span className="icon-long-arrow-right mr-2"></span>Consultoría Tecnológica</Link></li>
                <li className="link_footer"><Link to="/provider-wisp"><span className="icon-long-arrow-right mr-2"></span>Proveedor WISP</Link></li>
              </ul>
            </div>
          </div>
          <div className="col-md">
            <div className="ftco-footer-widget mb-4">
            	<h2 className="ftco-heading-2">¿Tienes dudas?</h2>
            	<div className="block-23 mb-3">
	              <ul>
	                <li><FaMapMarker color="#fff" size="1.2em" /><span className="text">&nbsp; Av. 28 entre calles 28 y 29 Centro Profesional Padre Pio sector Campo Lindo, Acarigua Edo. Portuguesa.</span></li>
	                <li><a href="tel://4245149117" ><FaPhone color="#fff" size="1.2em" /><span className="text">&nbsp; + 58 424-5149117</span></a></li>
	                <li><a href="mailto:contacto@metalinks.com.ve"><FiMail color="#fff" size="1.2em" /><span className="text">&nbsp; contacto@metalinks.com.ve</span></a></li>
	              </ul>
	            </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 text-center">

            <p>
                Copyright &copy;<script>document.write(new Date().getFullYear());</script> Todos los derechos reservados | 2020 por <a href="/" target="_blank">Metalinks C.A</a>
            </p>
          </div>
        </div>
      </div>
    </footer>

    
    </>
    
  )
}

export default Footer
